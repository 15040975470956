import styled from "@emotion/styled";
import { Badge, Box, Button, IconButton } from "@mui/material";
import { FC, useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { getShowroomUrl } from "../../../../services/getShowroomUrl";
import { AddAPhoto, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import furnitureController from "../../../../Api/furnitureController";
import { useToast } from "../../../../hooks/useToast";
import { useUser } from "../../../../hooks/useUser";
import CustomDialog from "../../../../components/_common/CustomDialog";

const BASE_URL = getShowroomUrl();

const StyledPhoto = styled.img`
    width: 70px;
    height: 70px;
    object-fit: contain;
`;

const SlidesViewer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 940px){
        display: none;
    }
`

const CustomBadge = styled(Badge)`
    @media screen and (min-width: 940px){
        display: none;
    }

`

const AddIconBox = styled(Box)`
    display: flex;
    justify-content: flex-end;
`

interface ImageComponentProps {
    showEditTools: boolean;
    images: string[];
    thumbnailImages: string[];
    furnitureId: string;
    onSetLoading: (state: boolean) => void;
    onRefreshFurniture: () => void;
    indexToStartDisplay: number;
    onSetIndexToStartDisplay: (index: number) => void;
    showImageView: boolean;
    onSetShowImageView: (state: boolean) => void;
}

const ImageComponent: FC<ImageComponentProps> = ({
    images,
    furnitureId,
    showEditTools,
    thumbnailImages,
    indexToStartDisplay,
    onSetIndexToStartDisplay,
    onSetLoading,
    onRefreshFurniture,
}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [index, setIndex] = useState(indexToStartDisplay);
    const toast = useRef(useToast()).current;
    const user = useUser()
    const showAdminTools = (user.isAuthMinsk || user.isAuthPiaseczno) && showEditTools;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showImageView, setShowImageView] = useState(false);


    const addImages = async (images: File[]) => {
        onSetLoading(true);

        const res = await furnitureController.addImagesToSingleFurniture(furnitureId, images);

        onSetLoading(false);

        if (!res.ok) return toast.error(`Nie dodano zdjęcia: ${res.message}`)

        onRefreshFurniture();
    }


    const deleteImage = async () => {
        if (images.length === 1) return toast.error('Nie można usunąć ostatniego zdjęcia');
        onSetLoading(true);
        const res = await furnitureController.deleteImageFromFurniture(furnitureId, images[index]);

        onSetLoading(false);

        if (!res.ok) return toast.error(`Nie usunięto zdjęcia: ${res.message}`)

        setIndex(index === 0 ? 0 : index - 1)
        onSetIndexToStartDisplay(index === 0 ? 0 : index - 1)

        onRefreshFurniture();
    }


    const deleteImagePath = async () => {
        if (images.length === 1) return toast.error('Nie można usunąć ostatniego zdjęcia');
        onSetLoading(true);
        const res = await furnitureController.removeImagePathFromFurniture(furnitureId, images[index]);

        onSetLoading(false);

        if (!res.ok) return toast.error(`Nie usunięto zdjęcia: ${res.message}`)

        setIndex(index === 0 ? 0 : index - 1)
        onSetIndexToStartDisplay(index === 0 ? 0 : index - 1)

        onRefreshFurniture();
    }


    const moveImage = async (direction: 'up' | 'down') => {
        onSetLoading(true);
        const res = await furnitureController.moveFurnitureImage(furnitureId, images[index], direction);
        onSetLoading(false);

        if (!res.ok) return toast.error(`Nie przeniesiono zdjęcia: ${res.message}`)
        onRefreshFurniture();
        if (direction === 'up') {
            setIndex(index - 1);
            onSetIndexToStartDisplay(index - 1);
        }
        if (direction === 'down') {
            setIndex(index + 1);
            onSetIndexToStartDisplay(index + 1);
        }
    }


    const handleFileSelect = (e: any) => {
        const files = Array.from(e.target.files) as File[];
        addImages(files);
    };


    const toggleOpen = (state: boolean) => () => setShowImageView(state);


    const updateIndex = ({ index: current }: { index: number }) =>
        setIndex(current);


    const thumbnailSlides = thumbnailImages?.map(photoUrl => ({ src: BASE_URL + photoUrl }));
    const slides = images?.flatMap(photoObj => photoObj).map(photoUrl => ({ src: BASE_URL + photoUrl }));


    return (
        <>
            <CustomBadge
                sx={{
                    position: 'absolute',
                    right: 30,
                }}
                badgeContent={`${index !== undefined ? index + 1 : 1}/${slides?.length}`}
                color="primary"
            />
            <Lightbox
                styles={{ container: { backgroundColor: "rgba(255, 255, 255, 0.8)" } }}
                index={index}
                slides={slides}
                plugins={[Inline]}
                on={{
                    view: updateIndex,
                    click: toggleOpen(true),
                }}
                carousel={{
                    padding: 0,
                    spacing: 0,
                    imageFit: "contain",
                }}
                inline={{
                    style: {
                        maxHeight: "55vh",
                        width: "100%",
                        aspectRatio: "3 / 2",
                        margin: "0 auto",
                        cursor: 'pointer'
                    },
                }}
            />
            {showAdminTools &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                        sx={{ m: 1 }}
                        onClick={() => moveImage('up')}
                    >
                        <ArrowUpward color="secondary" />
                    </IconButton>
                    <IconButton sx={{ m: 1 }} color='error' onClick={() => setDeleteDialogOpen(true)}>
                        <Delete />
                    </IconButton>
                    <IconButton>
                        <Button onClick={deleteImagePath} color='error'>
                            Usuń path
                        </Button>
                    </IconButton>
                    <IconButton sx={{ m: 1 }} onClick={() => moveImage('down')}>
                        <ArrowDownward color="secondary" />
                    </IconButton>
                </Box>
            }
            <SlidesViewer>
                {thumbnailSlides.slice(0, 8).map((slide, index) => (
                    <Button
                        sx={{ mt: 1 }}
                        key={index}
                        onClick={() => { setIndex(index); }}
                        color="primary"
                    >
                        <StyledPhoto src={slide.src} alt="furniture" />
                    </Button>
                ))}
            </SlidesViewer>
            {showAdminTools &&
                <>
                    <AddIconBox
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <IconButton color="secondary">
                            <AddAPhoto />
                        </IconButton>
                    </AddIconBox>
                    <input
                        type="file"
                        multiple
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                </>
            }
            <Lightbox
                plugins={[Zoom]}
                open={showImageView}
                close={toggleOpen(false)}
                index={index}
                slides={slides}
                on={{ view: updateIndex }}
                animation={{ fade: 0 }}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
            />
            <CustomDialog
                onAgree={deleteImage}
                open={deleteDialogOpen}
                onSetOpen={setDeleteDialogOpen}
                title="Czy na pewno chcesz usunąć zdjęcie?"
                onDisagree={() => setDeleteDialogOpen(false)}
                description="Zdjęcie zostanie trwale usunięte z bazy danych."
            />
        </>
    )
}

export default ImageComponent;