import { FC, useEffect, useState } from "react";
import { IIMportedFurniture } from "../../../interfaces/IImportedFurniture";
import SalesWindow from "../../../components/_common/SalesWindow/SalesWindow";
import styled from "styled-components";
import noImage from "../../../assets/img/ufoBlack.png";

const FurnitureContainer = styled.div`
    width: 78vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: start;

    @media screen and (max-width: 940px) {
        width: 100%;
}
`

interface FurnitureProps {
    furnitureToDisplay: IIMportedFurniture[],

}

const Furniture: FC<FurnitureProps> = ({
    furnitureToDisplay,
}) => {
    const [showPerPage, setShowPerPage] = useState<number>(1);


    const findFeatureValue = (features: any[], featureName: string) => {
        if (!features) return null;
        const feature = features[0].feature.find((f: any) => f.name[0] === featureName);
        return feature ? feature.value[0] : null;
    }


    const showPerPageHandler = () => {
        document.documentElement.scrollHeight - window.innerHeight <= window.scrollY + 1000 && setShowPerPage(showPerPage + 1);
    }


    window.addEventListener('scroll', showPerPageHandler)


    useEffect(() => {
        setShowPerPage(1);
    }, [furnitureToDisplay]);


    return (
        <FurnitureContainer>
            {
                furnitureToDisplay.map((furniture: IIMportedFurniture, index: number) => {
                    const width = findFeatureValue(furniture?.features, "Szerokość");
                    const height = findFeatureValue(furniture?.features, "Wysokość");
                    const depth = findFeatureValue(furniture?.features, "Długość");

                    return (
                        showPerPage * 20 >= index - 1 && (
                            <SalesWindow
                                type="imported furniture"
                                key={furniture.symbol}
                                link={`mebel-importowany/${furniture.symbol}`}
                                id={furniture.symbol}
                                name={furniture.name}
                                width={width}
                                height={height}
                                depth={depth}
                                isPriceVissible={furniture.isPriceVissible}
                                image={furniture?.photos?.[0]?.photo?.[0] || noImage}
                            />
                        )
                    )
                })
            }
        </FurnitureContainer>
    )
}

export default Furniture;