import { TextField } from "@mui/material";
import { FC } from "react";

interface CustomInputProps {
    label?: string,
    value?: string | number | null,
    setValue?: (value: any) => void,
    mode: "text" | "search" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | "multiline",
    placeholder?: string,
    id?: string,
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning",
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void, // Dodajemy prop onKeyDown
    className?: string;
    variant?: "standard" | "filled" | "outlined";
    error?: boolean;
    rows?: number;
    helperText?: string;
    maxRows?: number;
    sx?: any;
    uppercase?: boolean;
}

const CustomInput: FC<CustomInputProps> = ({
    mode,
    setValue,
    value,
    label,
    placeholder,
    id,
    color,
    onKeyDown,
    className,
    variant,
    error,
    rows,
    helperText,
    maxRows,
    sx,
    uppercase,
}) => {

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!setValue) return;
        let value = event.target.value;
        const cleaned = value.replace(/\D/g, '');

        const inputEvent = event.nativeEvent as InputEvent;
        if (inputEvent.inputType === "deleteContentBackward") {
            if (value.endsWith('-')) {
                value = value.slice(0, -1); // remove only the last digit, leave the dash
            }
            setValue(value);
            return;
        }

        if (cleaned.length <= 9) { // limit to 9 digits
            const match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,3})?$/);
            if (match) {
                const formatted = `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}${cleaned.length % 3 === 0 && cleaned.length < 9 ? '-' : ''}`;
                setValue(formatted);
            }
        }
    };



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!setValue) return;
        if (uppercase) return setValue(e.target.value?.toUpperCase() || "");
        setValue(e.target.value?.toString() || "");
    };

    if (mode === 'text') {
        return (
            <TextField
                label={label || ''}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                color={color}
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                inputMode="text"
                type="text"
                sx={sx}
            />
        )
    }

    if (mode === 'numeric') {
        return (
            <TextField
                onWheel={(e) => e.preventDefault()}
                label={label || ''}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                color={color}
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                inputMode="numeric"
                type="number"
                sx={sx}
            />
        )
    }

    if (mode === 'search') {
        return (
            <TextField
                label={label || 'Wyszukaj...'}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                color={color}
                inputMode='search'
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                type="search"
                sx={sx}
            />
        )
    }

    if (mode === 'email') {
        return (
            <TextField
                label={label || 'Email'}
                type='email'
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                color={color}
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                inputMode="email"
                error={error}
                helperText={error && (helperText || 'Wpisz poprawny adres email')}
                sx={sx}
            />
        )
    }

    if (mode === 'tel') {
        return (
            <TextField
                label={label || 'Telefon'}
                type='tel'
                value={value}
                onChange={handleChangePhone}
                placeholder={placeholder}
                id={id}
                color={color}
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                inputMode="tel"
                error={error}
                helperText={error && (helperText || 'Wpisz numer telefonu w formacie 123-456-789')}
                sx={sx}
            />
        )
    }

    if (mode === 'multiline') {
        return (
            <TextField
                label={label || 'Wpisz tekst'}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                id={id}
                color={color}
                onKeyDown={onKeyDown}
                className={className}
                variant={variant || 'outlined'}
                multiline
                rows={rows}
                maxRows={maxRows || 4}
                sx={sx}
            />
        )
    }

    return (<>Wrong input type</>);
}

export default CustomInput;